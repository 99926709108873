import { useState } from 'react';
import Context, { useContext } from '../../context';
/* Commented out for the theme */
// import { BsFillCheckSquareFill } from 'react-icons/bs';
import { FaCheckCircle as BsFillCheckSquareFill } from 'react-icons/fa';
/* Commented out for the theme */
// import { ImCheckboxUnchecked } from 'react-icons/im';
import { FaRegCircle as ImCheckboxUnchecked } from 'react-icons/fa';
import { Container, Button, Row, Col } from 'reactstrap';
import Excel, { ExcelIcon} from '../Excel';
import { products } from '../../utils/distro';
import ExcelReport, { getTrans, getNavs } from '../ExcelReport';
import './index.css';

let loaded = 0;
let level = '';
let sortfield = 'id';
let accts = [];
let newaccts= [];
let setaccs = () => void 0;
let sorting = 0;
let currencies = {};

// Currency selection
// let actcur = '';

// Currency deselection
let inactcurs = [];
let tempcur = '';

let account = {};
let accounts = {};
let status = {};
let classes = {};
let statval = true;
let totalusd = 0;
let totalils = 0;
let accountsOpts = {};
let build = 0;
let setcur = 0;
let accountids = [];
let acctsData = {};
let curbtns = null;
let refilter = () => void 0;
let acctnames = {};
let lastDate = '';
let sumaccts = 0;
let newfields = {};

let relate = [];
let conts = [];
let dropsearch = null;
let dsLabel = '';

// Added for USD or ILS not 0 then it is active
let stats = {};

const initinvestor = () => {
  if(!loaded){
    setNewFields();

    account = document.querySelector('.account');

    curbtns = account.querySelectorAll('.currencies button');
    curbtns.forEach((a) => a.addEventListener('click', () => filByCur(1)));

    const filters2 = account.querySelectorAll('.filter select');
    accounts = filters2[1]; status = filters2[2]; classes = filters2[3];
    status.addEventListener('change', filByStat);

    const clear = account.querySelector('.clear');
    clear.addEventListener('click', clearfil);

    const cleartext = account.querySelector('.cleartext2');
    cleartext.addEventListener('click', clearfil);

    // This worked when dispatch was added in Dropsearch component
    dropsearch = account.querySelector('.dropsearch select');
    dropsearch.addEventListener('change', filtration);
    dsLabel = dropsearch.textContent;

    const view = document.querySelector('.viewmode select');
    if(view) view.addEventListener('change', () => mode(view.value));

    const sortAccts = localStorage.sortAccts;
    if(sortAccts) sortfield = sortAccts;

    setcur = 1;
    filByCur();

    loaded = 1;
    // console.log('accts', accts);
  }
};

const setNewFields = () => {
  newfields = JSON.parse(JSON.stringify(fields));
  if(level !== 'admin'){
    delete newfields.ManagementFee;
    delete newfields.Success;
    delete newfields.Series;
    delete newfields.IRA__c;
    delete newfields.Investment_Type__c;
    delete newfields.Investor_type__c;
    delete newfields.TZUR_Id__c;
  }
};

const mode = (val) => {
  val = val.toLowerCase();

  document.querySelectorAll
  (`.level.${level}`).forEach((a) => {
    a.classList.remove(level);
    a.classList.add(val);
  });

  level = val;
  setNewFields();
  filter();
};

const filByCur = () => build = 1;
const filByStat = () => build = 1;

// Currency selection
// const clearfil = () => build = 1;

// Currency deselection
const clearfil = () => {
  setcur = 1;
  build = 1;
};

const filtration = () => {
  setcur = 1;
  build = 1;
};

const fields = {
  Active__c: ['ACTIVE', 'string'],
  Name: ['ACCOUNT NAME', 'string'],
  Fund__c: ['FUND NAME', 'string'],
  CurrencyIsoCode: ['CURRENCY', 'string'],
  Total_USD: ['TOTAL USD', 'number'],
  Total_ILS: ['TOTAL ILS', 'number'],
  Class: ['CLASS', 'string'],
  MTD: ['MTD', 'percent'],
  TTM: ['TTM', 'percent'],
  ITD: ['ITD', 'percent'],
  Distribution: ['DISTRIBUTION', 'string'],
  ManagementFee: ['MANAGEMENT FEE', 'number'],
  Success: ['SUCCESS', 'number'],
  Money_Source__c: ['SUBSCRIPTION TYPE', 'string'],
  Series: ['SERIES', 'string'],
  IRA__c: ['IRA', 'string'],
  Investment_Type__c: ['INVESTMENT TYPE', 'string'],
  Investor_type__c: ['INVESTOR TYPE', 'string'],
  TZUR_Id__c: ['TZUR ID', 'string']
};

const TempTh = ({id, children}) => {
  const sf = sortfield.split(' ');
  let down = '', up = '';
  if(sf[0] === id){
    if(!sf[1]) up = ' active';
    else down = ' active';
  }

  return (
    <th id={id} onClick={() => sortby(id)}>
      {/* Use '\xa0' instead of '&nbsp' in strings */}
      {/* Non-breakable space is char 0xa0 (160 dec) */}
      {/* Use the following to compare if (x == '\xa0') */}
      {/* Or use if (x == String.fromCharCode(160)) */}
      {children.replace(/ /g, '\xa0')}&nbsp;
      <span className={"down"+down}>&#9660;</span>
      <span className={"up"+up}>&#9650;</span>
    </th>
  );
};

const string = (field) => {
  sort(newaccts, field);
};

const sort = (data, field) => {
  data.sort((a, b) => {
    let fa = a[field],
        fb = b[field];

    fa = fa? fa.toLowerCase() : '';
    fb = fb? fb.toLowerCase() : '';

    if (fa < fb) return -1;
    if (fa > fb) return 1;
    return 0;
  });
};

const _string = (field) => {
  newaccts.sort((a, b) => {
    let fa = a[field],
        fb = b[field];

    fa = fa? fa.toLowerCase() : '';
    fb = fb? fb.toLowerCase() : '';

    if (fa < fb) return 1;
    if (fa > fb) return -1;
    return 0;
  });
};

const sortby = (field, load = 0) => {
  sorting = 1;

  let sort = (field === sortfield)? ' desc' : '';

  if(!load){
    sortfield = field+sort;
    localStorage.sortAccts = sortfield;
  } else sort = (!sort)? ' desc' : '';

  if(field === 'id'){
    if(!sort) string('Name');
    else _string('Name');
  } else if(
    newfields[field][1] === 'number' ||
    newfields[field][1] === 'percent'
  ){
    if(!sort)
      newaccts.sort((a, b) => a[field] - b[field]);
    else
      newaccts.sort((a, b) => b[field] - a[field]);
  } else {
    if(!sort) string(field);
    else _string(field);
  }

  setaccs();
};

const filter = () => {
  setTimeout(() => {
    newaccts = [];
    totalusd = 0;
    totalils = 0;
    accountsOpts = {};
    acctnames = {};

    const accountsval = accounts.value;
    const classesval = classes.value;

    if(sumaccts) accts = sumaccts;
    let discur = [...new Set(accts.map(a => {
      let cur = '';
      const curcode = currencies[a.CurrencyIsoCode.toLowerCase()];
      const accid = a.id;

      // Show reseller summary accounts
      if(
        level === 'reseller' &&
        dropsearch.value === '-'
      ){
        const acctNav = a.TotalNavAumUSD__c;
        const acctNavIls = a.TotalNavAum_ILS__c;
        stats[accid] = (acctNav || acctNavIls) > 0;
        acctsData[accid] = {
          nav: acctNav,
          navIls: acctNavIls,
          class: ''
        };
      }
      // /Show reseller summary accounts

      // Replaced with USD or ILS not 0 then it is active
      // const stat = a.Active__c;
      const stat = (stats[accid])? stats[accid] : false;

      a.Active__c = stat.toString();

      // Currency selection
      // const curval = (actcur)? actcur : curcode;

      // Currency deselection
      const incurlist = Object.values(currencies).indexOf(curcode) >= 0;
      let curval = true;
      if(tempcur) curval = tempcur === curcode;
      else if(inactcurs.length) curval = inactcurs.indexOf(curcode) < 0;

      const accsval = (accountsval !== '-')? accountsval : accid;
      const sttval = (statval !== '')? statval : stat;
      const accids = (accountids.length)? accountids : accid;

      if(
        // Currency selection
        // curval === curcode &&

        // Currency deselection
        // The incurlist is to filter the data based on listed currency
        // In case of new currency it will not be included
        incurlist &&
        curval &&

        accsval === accid &&
        sttval === stat &&
        accids.indexOf(accid) >= 0
      ){
        // Init accounts data
        const acctsdata = acctsData[accid];

        // Extract accounts data
        let nav = 0; let navIls = 0;
        let clss = ''; let srsn = '';
        let ttm = 0; let ttmIls = 0;
        let mtd = 0; let mtdIls = 0;
        let itd = 0; let itdIls = 0;
        let lrd = '';
        if(acctsdata){
          nav = acctsdata.nav;
          navIls = acctsdata.navIls;
          clss = acctsdata.class;
          srsn = acctsdata.series;
          ttm = acctsdata.ttm;
          ttmIls = acctsdata.ttmIls;
          mtd = acctsdata.mtd;
          mtdIls = acctsdata.mtdIls;
          itd = acctsdata.itd;
          itdIls = acctsdata.itdIls;
          lrd = acctsdata.lastReportedDate;
        }

        // Set classes filter value
        const clssval = (classesval !== '-')? classesval : clss;

        // Classes filter
        if(clssval === clss){
          // Set currency
          cur = curcode;

          // Add custom data in newaccts object
            if(a.CurrencyIsoCode === 'USD'){
              a.Total_USD = nav;
              a.Total_ILS = 0;
              totalusd += nav;

              a.TTM = ttm;
              a.MTD = mtd;
              a.ITD = itd;
            } else {
              a.Total_USD = 0;
              a.Total_ILS = (navIls)? navIls : nav;
              totalils += a.Total_ILS;

              a.TTM = (ttmIls)? ttmIls : ttm;
              a.MTD = (mtdIls)? mtdIls : mtd;
              a.ITD = (itdIls)? itdIls : itd;
            }

            a.Class = clss;

            const distro = products(clss);
            a.Distribution = '';
            a.ManagementFee = '';
            a.Success = '';

            if(distro){
              a.Distribution = distro.family;
              a.ManagementFee = distro.fee;
              a.Success = distro.success;
            }

            a.Series = srsn;
            a.lastReportedDate = lrd;
          // /Add custom data in newaccts object

          // Collect account names
          acctnames[accid] = a.Name;

          // Push qualified accounts
          newaccts.push(a);
        }
      }

      // Show reseller summary accounts
      if(
        level !== 'reseller' ||
        dropsearch.value !== '-'
      ){
        if(
          // Currency selection
          // curval === curcode &&

          // Currency deselection
          // The incurlist is to filter the data based on listed currency
          // In case of new currency it will not be included
          incurlist &&
          curval &&

          sttval === stat &&
          accids.indexOf(accid) >= 0
        )
          accountsOpts[accid] = a.Name;
      }

      return cur;
    }))];

    // Remove empty element
    discur = discur.filter(a => a);

    // Reexecute account filter
    if(discur.length === 1)
      refilter(discur[0]);

    if(setcur){
      curbtns.forEach((a) => {
        a.classList.add('active');
        if(discur.indexOf(a.innerText) >= 0)
          a.disabled = false;
        else
          a.disabled = true;
      });
      setcur = 0;
    }

    if(build){
      buildfil(accountsOpts, accounts);
      build = 0;
    }

    sortby(sortfield.split(' ')[0], 1);
  }, 100);
};

const buildfil = (options, elem) => {
  const arr = [];
  for(const key in options){
    arr.push({key, val: options[key]});
  }

  sort(arr, 'val');

  arr.forEach(a => {
    elem.innerHTML += `<option value="${a.key}">${a.val}</option>`;
  });
};

const asExcel = () => {
  const usd = {
    numFmt: `${currencies.usd}#,##0`
  };

  const ils = {
    numFmt: `${currencies.ils}#,##0`
  };

  const percent = {
    numFmt: '0.00"%"'
  };

  const newdata = newaccts.map(a => {
    const columns = {};
    for(const key in newfields){
      let val = a[key];
      val = (val)? val : '';
      if(key.indexOf('Total') >= 0){
        if(val) val = parseFloat(val).toFixed(0);
        if(val){
          if(key.indexOf('USD') >= 0)
            val = { v: val, t: 'n', s: usd };
          else val = { v: val, t: 'n', s: ils };
        }
      } else if(newfields[key][1] === 'percent'){
        if(val) val = { v: val.toFixed(2), t: 'n', s: percent };
      }
      columns[newfields[key][0]] = val;
    }
    return columns;
  });

  const columns = {};
  for(const key in newfields){
    let val = '';
    if(key.indexOf('Total') >= 0){
      if(key.indexOf('USD') >= 0){
        val = (totalusd)? parseFloat(totalusd).toFixed(0) : '';
        if(val) val = { v: val, t: 'n', s: usd };
      } else {
        val = (totalils)? parseFloat(totalils).toFixed(0) : '';
        if(val) val = { v: val, t: 'n', s: ils };
      }
    }
    columns[newfields[key][0]] = val;
  }
  newdata.push(columns);

  Excel(newdata, 'accounts');
};

const toggle = (evnt) => {
  const elem = evnt.currentTarget;
  elem.classList.toggle('active');
  const panel = elem.parentElement;
  if(!panel.classList.contains('hide')){
    panel.style.height = '43px';
    panel.classList.add('hide');
  } else {
    panel.style.height = 'auto';
    panel.classList.remove('hide');
  }
};

const Temp = ({label, value}) => {
  return (
    <Col md={3}>
      {label}<br /><b>{value}</b>
    </Col>
  );
};

const detail = (actacct, key) => {
  return (actacct.id)? actacct[key] : 'All';
};

const click = (id) => {
  // Show reseller summary accounts
  if(
    level === 'reseller' &&
    dropsearch.value === '-'
  ){
    const ids = relate.find(a => a.AccountId === id);
    const details = conts.find(a => a.id === ids.ContactId);

    const selopt = dropsearch.options[0];
    selopt.innerText = details.Name;
    selopt.value = details.id;

    const change = new Event('change', { bubbles: true });
    dropsearch.dispatchEvent(change);
  } else {
    let val = accounts.value;
    val = (val !== id)? id : '-';
    accounts.value = val;
    const change = new Event('change', { bubbles: true });
    accounts.dispatchEvent(change);
  }
};

// Show reseller summary accounts
const ShowAll = () => {
  return (
    <>
      &emsp;
      <span onClick={showResSummary}>
        Show All Records
      </span>
    </>
  );
};

const showResSummary = () => {
  const selopt = dropsearch.options[0];
  selopt.innerText = dsLabel;
  selopt.value = '-';

  const change = new Event('change', { bubbles: true });
  dropsearch.dispatchEvent(change);
};
// /Show reseller summary accounts

function Investor(){
  if(loaded && !sorting) filter(); else sorting = 0;

  const Contexts = useContext(Context);
  level = Contexts.level;
  accts = JSON.parse(Contexts.accts);
  relate = JSON.parse(Contexts.relate);
  conts = JSON.parse(Contexts.conts);

  // Added for USD or ILS not 0 then it is active
  stats = Contexts.stats;

  const [accs, setAccs] = useState([]);
  setaccs = () => setAccs(newaccts);

  currencies = Contexts.currencies;

  // Currency selection
  // actcur = Contexts.actcur;

  // Currency deselection
  inactcurs = Contexts.inactcurs;
  tempcur = Contexts.tempcur;

  const actacct = Contexts.actacct;
  accountids = actacct.AccountIds;
  statval = Contexts.statval;
  acctsData = Contexts.acctsData;
  refilter = Contexts.refilter;
  Contexts.acctnames = acctnames;
  lastDate = Contexts.lastDate;

  // No reseller summary accounts
  // sumaccts = Contexts.sumaccts;

  // Show reseller summary accounts
  sumaccts = (level === 'reseller')?
  accts.slice() : Contexts.sumaccts;

  // For excel report
  getTrans(JSON.parse(Contexts.trans));
  const newnavs = Contexts.newnavs;
  if(newnavs) getNavs(JSON.parse(newnavs));

  newaccts = accs.slice();

  return (
    <Container fluid className="investorinfo">
      <div className="details" style={{height: 'auto'}}>
        <button className="accordion h5 active" onClick={toggle} tabIndex="-1">
          Investor Information
        </button>
        <div className="info">
          <Row>
            <Temp label="Name" value={detail(actacct, 'Name')} />
            <Temp label="Nick Name" value={detail(actacct, 'Nick_Name__c')} />
            <Temp label="Email" value={detail(actacct, 'Email')} />
            <Temp label="Mobile" value={detail(actacct, 'MobilePhone')} />
          </Row>
        </div>
        <div className="infonote">Information presented as of {lastDate}</div>
        <h5>Accounts</h5>
        <Button color="primary" size="sm" className="accountexport" onClick={asExcel}>
          <ExcelIcon /> Export to Excel
        </Button>
        {/* <div className="accts"> Use this when the excel report is ready */}
        <div className={`accts ${level}`}>
          <div className="table">
            <table>
              <thead>
                <tr key="totals">
                  <th colSpan="3" key="id">
                    <div>
                      <b>TOTAL ({accs.length} record{accs.length > 1? 's' : ''})</b>
                      {/* Show reseller summary accounts */}
                      {(
                        (level === 'admin' || level === 'reseller') &&
                        ((dropsearch && dropsearch.value !== '-') ||
                        (accounts && accounts.value !== '-'))
                      )? <ShowAll /> : ''}
                    </div>
                  </th>
                  {(() => {
                    const columns = [];
                    for(const key in newfields){
                      let val = '';
                      if(key.indexOf('Total') >= 0){
                        if(key.indexOf('USD') >= 0){
                          val = (totalusd)? totalusd.toLocaleString('en-US', {maximumFractionDigits: 0}) : '';
                          if(val) val = <b>{currencies.usd+val}</b>;
                        } else {
                          val = (totalils)? totalils.toLocaleString('en-US', {maximumFractionDigits: 0}) : '';
                          if(val) val = <b>{currencies.ils+val}</b>;
                        }
                      }

                      if(key !== 'Active__c' && key !== 'Name')
                        columns.push(<th key={key}><div>{val}</div></th>);
                    }
                    return columns;
                  })()}
                </tr>
                <tr>
                  {/* <TempTh key="id" id="id">#</TempTh> */}
                  <th>REPORT</th>
                  {(() => {
                    const columns = [];
                    for(const key in newfields){
                      columns.push(<TempTh key={key} id={key}>{newfields[key][0]}</TempTh>);
                    }
                    return columns;
                  })()}
                </tr>
              </thead>
              <tbody>
                {
                  accs.map((a, b) => {
                    const excelData = {
                      id: a.id,
                      lastReportedDate: a.lastReportedDate,
                      Name: a.Name,
                      Class: a.Class
                    };

                    return (
                      <tr key={b}>
                        {/* <td key="id"><div>{b + 1}</div></td> */}
                        <td><ExcelReport data={excelData} /></td>
                        {(() => {
                          const columns = [];
                          for(const key in newfields){
                            let val = a[key];
                            val = (val)? val : '';
                            if(key === 'Active__c'){
                              let Icon = BsFillCheckSquareFill;
                              let color = '#0d6efd';

                              if(val === 'false'){
                                Icon = ImCheckboxUnchecked;
                                color = 'gray';
                              }

                              columns.push(<td key={key}><div><Icon size="15" color={color} /></div></td>);
                            } else {
                              if(key.indexOf('Total') >= 0){
                                val = val.toLocaleString('en-US', {maximumFractionDigits: 0});
                                if(val){
                                  if(key.indexOf('USD') >= 0)
                                    val = currencies.usd+val;
                                  else val = currencies.ils+val;
                                }
                              } else if(key === 'Name'){
                                val = <span onClick={() => click(a.id)}>{val}</span>
                              } else if(newfields[key][1] === 'percent'){
                                if(val) val = val.toFixed(2)+'%';
                              }

                              columns.push(<td key={key}><div>{val}</div></td>);
                            }
                          }
                          return columns;
                        })()}
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Investor;
export { initinvestor };
