import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
import XLSX from 'xlsx-js-style';
import { ReactComponent as ExcelIcon } from '../../libs/theme/excel.svg';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const ext = 'xlsx';

function Excel(data, filename){
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: ext, type: 'array' });
  const blob = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blob, `${filename}.${ext}`);
}

export default Excel;
export { ExcelIcon };