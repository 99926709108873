import { useState } from 'react';
import Context, { useContext } from '../../context';
import ReactDOMServer from 'react-dom/server';
import { ReactComponent as DownloadIcon } from '../../libs/theme/download.svg';
import './index.css';

import {
  Modal, ModalHeader,
  ModalBody, ModalFooter, Button
} from 'reactstrap';

import {
  loaderShow, loaderHide,
  loaderMsg, loaderErrMsg
} from '../Loader';

import { Container } from 'reactstrap';
import { NewToken } from '../Token';
import Dropmultisearch from '../Dropmultisearch';

let loaded = 0;
let sortfield = 'CloseDate desc';
let comms = [];
let newcomms = [];
let accounts = [];
let setcomms = () => void 0;
let modalElem = null;

const initcomms = () => {
  if(!loaded){
    const dropsearch = document.querySelector('.dropsearch select[name="accounts"]');
    if(dropsearch){
      dropsearch.addEventListener('change', () => {
        if(dropsearch.value !== '-'){
          const account = dropsearch.innerText || dropsearch.textContent;
          newcomms = comms.filter
          (a => a.Account_Name === account);
        } else newcomms = accounts;

        setTimeout(() => {
          sortby(sortfield.split(' ')[0], 1);
        }, 100);
      });
    }

    const sortComms = localStorage.sortComms;
    if(sortComms) sortfield = sortComms;
    sortby(sortfield.split(' ')[0], 1);

    window.scrollTo(0, 0);
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    loaded = 1;
  }
};

const fields = {
  Name: ['Opportunity Name', 'string'],
  Account_Name: ['Account Name', 'string'],
  CloseDate: ['Close Date', 'string'],
  StageName: ['Stage', 'string']
};

const TempTh = ({id, children}) => {
  const sf = sortfield.split(' ');
  let down = '', up = '';
  if(sf[0] === id){
    if(!sf[1]) up = ' active';
    else down = ' active';
  }

  return (
    <th id={id} onClick={() => sortby(id)}>
      {children.replace(/ /g, '\xa0')}&nbsp;
      <span className={"down"+down}>&#9660;</span>
      <span className={"up"+up}>&#9650;</span>
    </th>
  );
};

const string = (field) => {
  newcomms.sort((a, b) => {
    let fa = a[field],
        fb = b[field];

    fa = fa? fa.toLowerCase() : '';
    fb = fb? fb.toLowerCase() : '';

    if (fa < fb) return -1;
    if (fa > fb) return 1;
    return 0;
  });
};

const _string = (field) => {
  _sort(newcomms, field);
};

const _sort = (data, field) => {
  data.sort((a, b) => {
    let fa = a[field],
        fb = b[field];

    fa = fa? fa.toLowerCase() : '';
    fb = fb? fb.toLowerCase() : '';

    if (fa < fb) return 1;
    if (fa > fb) return -1;
    return 0;
  });
};

const sortby = (field, load = 0) => {
  let sort = (field === sortfield)? ' desc' : '';

  if(!load){
    sortfield = field+sort;
    localStorage.sortComms = sortfield;
  } else sort = (!sort)? ' desc' : '';

  if(!sort) string(field);
  else _string(field);

  setcomms();
};

const files = (id) => {
  loaderShow();

  NewToken().then(newtoken => {
    fetch(`${process.env.REACT_APP_API}/auth/commfiles/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          const files = res.data;

          const icon = ReactDOMServer
          .renderToString(<DownloadIcon />);

          files.forEach(a => {
            const elem = document.createElement('div');

            const title = a.Title+'.'+a.fileExtension;
            const titleElem = document.createElement('span');
            titleElem.className = 'title';
            titleElem.innerHTML = title;

            const dlElem = document.createElement('span');
            dlElem.className = 'file';
            dlElem.addEventListener('click', () => download(a));
            dlElem.innerHTML = icon+' Download';

            elem.append(titleElem, dlElem);
            modalElem.querySelector('.files').append(elem);

            // Add title if text has ...
            if(titleElem.scrollWidth > titleElem.clientWidth){
              // titleElem.setAttribute('data-title', title);
              // titleElem.classList.add('show');
              titleElem.title = title;
            }
          });

          loaderHide();
        } else {
          if(res.message)
            loaderMsg(res.message);
          else loaderErrMsg();
        }
      })
      .catch(err => {
        loaderErrMsg();
        console.log(err);
      });
  });
};

const download = (file) => {
  // Auto download
  const link = document.createElement('a');
  link.href = `data:${file.fileType};base64,${file.BlobAsBase64}`;
  link.download = `${file.Title}.${file.fileExtension}`;
  link.click();
};

function Commissions(){
  const Contexts = useContext(Context);
  const viewmode = Contexts.viewmode;
  comms = JSON.parse(Contexts.comms);

  // Sort comms by latest CloseDate
  _sort(comms, 'CloseDate');

  if(viewmode === 'admin'){
    const names = [];
    accounts = comms.filter(a => {
      let data = false;
      if(names.indexOf(a.Account_Name) < 0){
        names.push(a.Account_Name);
        data = true;
      }
      return data;
    });
    newcomms = accounts;
  } else newcomms = comms;

  const [commissions, setComms] = useState(newcomms);
  setcomms = () => setComms(newcomms);

  newcomms = commissions.slice();

  const [modalFiles, setModalFiles] = useState(false);
  const toggleFiles = (name, id) => {
    setModalFiles(!modalFiles);

    if(!modalFiles){
      (function retry(){
        modalElem = document
        .querySelector('.filesModal');
        if(modalElem){
          const title = modalElem
          .querySelector('.modal-title span');
          title.innerText = name;

          files(id);
        } else setTimeout(retry, 400);
      })();
    }
  };

  return (
    <>
      <Container fluid className={`commissions ${viewmode}`}>
        <h4>Commissions</h4>
        {viewmode !== 'admin'? '' : <Dropmultisearch data={accounts} value="id" options={['Account_Name']} name="accounts" label="All Accounts" />}
        <div className="table comms">
          <table>
            <thead>
              <tr>
                {/* <TempTh key="id" id="id">#</TempTh> */}
                {(() => {
                  const columns = [];
                  for(const key in fields){
                    columns.push(<TempTh key={key} id={key}>{fields[key][0]}</TempTh>);
                  }
                  return columns;
                })()}
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {
                commissions.map((a, b) => {
                  return (
                    <tr key={b}>
                      {/* <td key="id"><div>{b + 1}</div></td> */}
                      {(() => {
                        const columns = [];
                        for(const key in fields){
                          let val = a[key];
                          val = (val)? val : '';
                          columns.push(<td key={key}><div>{val}</div></td>);
                        }
                        return columns;
                      })()}
                      <td>
                        {/* <div className="file" onClick={() => download(a.id)}> */}
                        <div className="file" onClick={() => toggleFiles(a.Name, a.id)}>
                          <DownloadIcon /> Download files
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </Container>
      <Modal isOpen={modalFiles} toggle={toggleFiles} centered={true} backdrop="static" modalClassName="filesModal">
        <ModalHeader toggle={toggleFiles}><span></span> files</ModalHeader>
        <ModalBody>
          <div className="files"></div>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="primary" onClick={toggleFiles}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Commissions;
export { initcomms };